import WebDev from '../src/images/web.svg';
import AndroidDev from '../src/images/android.svg';
import iOS from '../src/images/ios.png';

const ServiceData = [
    {
        imgSrc:WebDev,
        title:'Web Development',
        body:'We provide Web development services using technlogies such as Python(FastApi/Falsk),ReactJS,NodeJS,HTML5,CSS3 and JavaScript/TypeScript.',
    },
    {
        imgSrc:AndroidDev,
        title:'Android Development',
        body:'We provide Android development services using technologies such as Java,Kotlin for native android development.',
    },
    {
        imgSrc:iOS,
        body:'We provide iOS development services using technologies such as Swift for native android development.',
        title:'iOS Development',
    },
]


export default ServiceData;
